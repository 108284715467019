<template>
  <div class="header" :class="isFixed ? 'header-fixed' : ''">
    <div class="menu-left">
      <div v-for="item in leftMenu.filter(i => i.logo)" v-bind:key="item.path" class="company-heading-container"  @click="$emit('navigate-home')">
        <img  class="company-logo" :src="require(`../../assets/${item.path}`)" />
        <h1>{{$t(item.label)}}</h1>
      </div>
      <h2 v-for="item in leftMenu.filter(i => !i.logo)" v-bind:key="item.label" @click="$emit('navigate-home')" style="margin: 10px; cursor: pointer;">{{ $t(item.label)}}</h2>
    </div>
    <div class="menu-middle">
      <custom-button v-for="item in middleMenu" v-bind:key="item.label" v-bind="item" @on-click="href => $emit('navigate-home', href)"/>
    </div>
    <div class="menu-right">
      <custom-button v-for="item in rightMenu.filter(value => value.type !== 'badges')" v-bind:key="item.label" v-bind="item" />
      <store-badges v-if="rightMenu.some(value => value.type === 'badges')" :scale="0.7" />
      <!--<dropdown :items="$i18n.availableLocales" @value-change="value => $i18n.locale = value" style="margin-left: 10px;" />-->
    </div>
  </div>
</template>

<script>

import StoreBadges from "@/components/common/StoreBadges";
import CustomButton from "./GenericButton";
// import GenericDropdown from "@/components/generic/GenericDropdown";
export default {
name: "GenericHeader",
  components: {
    "custom-button": CustomButton,
    "store-badges": StoreBadges,
    // "dropdown": GenericDropdown
  },
  emits: [
      "navigate-home"
  ],
  props: {
    leftMenu: {
      type: Array,
      default: () => []
    },
    middleMenu: {
      type: Array,
      default: () => []
    },
    rightMenu: {
      type: Array,
      default: () => []
    },
    isFixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .menu-right {
    position: absolute;
    height: 100%;
    margin: 0 20px;
    right:10%;
    display: flex;
    align-items: center;
  }
  .menu-left {
    position: absolute;
    height: 100%;
    margin: 0 20px;
    left:10%;
    display: flex;
    align-items: center;
  }
  .menu-left h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 20px;
    color: #2E293D;
  }
  .menu-middle {
    height: 100%;
    margin: 0 20px;
    display: flex;
    align-items: center;
  }
  .company-logo {
    height: 62px;
    width: 62px;
  }
  .header {
    position: absolute;
    padding: 10px 10%;
    box-sizing: border-box;
    width: 100vw;
    top: 0;
    float: top;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
    transition: all 0.5s;
    display: flex;
  }
  .header-fixed {
    background-color: rgba(255,255,255,0.7);
    position: fixed;
    box-shadow: 0 2px 10px 5px lightgray;
    backdrop-filter: blur(10px);

  }
  .company-heading-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
</style>